<template>
  <div class="manage-bank-loan">
    <div class="container fluid">
      <h2 class="mx-2 my-4">Manage Bank Loan</h2>

      <h4 class="p-2">Leads</h4>
      <lead-table :getBankLoans="bankLoanAPI.getBankLoans"></lead-table>
    </div>
  </div>
</template>

<script>
import LeadTable from "@/components/GlobalComponents/Shared/Banker/LeadTable";
import { bankLoan as bankLoanAPI } from "@/api";

export default {
  components: {
    LeadTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      leadTab: "",
      bankLoanAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.manage-bank-loan {
}
</style>
